.page-404 {
    img {
        transform: rotate(30deg);
        margin-top: -20px;
    }

    h1 {
        font-size: 160px;
    }

    @include media-breakpoint-down(sm) {
        h1 {
            font-size: 90px;
        }

        img {
            width: 110px;
            height: 82px;
        }
    }
}