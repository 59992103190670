#main-content {
    [data-toggle="collapse"]:not(.original) {
        position: relative;
        display: block;
        color: $body-color;
    
        &:hover {
            color: $body-color;
            text-decoration: none;
        }
        
        &:before {
            content: '+';
            left: 0;
            top: 0;
            position: absolute;
            font-size: 1.5rem;
            line-height: 1;
            transform: rotate(0deg);
        }
    
        &[aria-expanded="true"] {
            &:before {
                transform: rotate(45deg);
            }
        }
    }
}

#definitionsCollapse.show + .text-left,
#definitionsCollapse.collapsing + .text-left {
    display: none;
}