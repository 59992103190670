.desktop {
    display: none;

    @include media-breakpoint-up(xl) {
        display: block;
    }
}
.tablet {
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
    }

    @include media-breakpoint-up(xl) {
        display: none;
    }
}
.mobile {
    width: 100%;
    display: none;

    @include media-breakpoint-down(md) {
        display: block;
    }
}