.text-underline {
    text-decoration: underline;
}

.text-nodeco {
    text-decoration: none !important;
}

.text-uc {
    text-transform: uppercase;
}

.text-lc {
    text-transform: lowercase;
}

.font-weight-semi {
    font-weight: 600;
}

.text-black {
    color: #000;
}

.text-gray {
    color: $gray-500 !important;
}

.text-secondary-dark {
    color: $secondary-dark;
}