.btn {
    text-decoration: none;

    &:focus, &:active {
        outline: 0 !important;
        box-shadow: none !important;
    }

    // &.btn-primary:hover, &.btn-primary:focus, &.btn-primary:active {
    //     background-color: $purple-light;
    //     border-color: $purple-light;
    // }

    &.btn-link {
        text-decoration: none !important;
        display: inline-block;
        border-bottom: 1px solid;
        border-radius: 0;
        padding: 0;
    }

    &.btn-outline-white {
        border-color: white;
        color: white;

        &:hover, &:focus, &:active {
            background-color: white;
            color: $primary;
        }
    }
}


.btn-svg-wrapper {
    width: 181px;
    height: 143px;
    text-align: center;
    color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .btn-svg-bg,
    .btn-svg-text {
        position: absolute;
        transform: translate(-50%, -50%);
    }
    
    .btn-svg-bg {
        fill: $primary;
        top: 50%;
        left: 50%;
        width: 143px;
        height: 113px;
        transition: $transition-base;
    }
    
    .btn-svg-text {
        top: calc(50% - 10px);
        left: calc(50% + 10px);
    }

    &:hover {
        color: white;

        .btn-svg-bg {
            width: 181px;
            height: 143px;
            fill: darken($primary, 5%);
        }
    }
}