.home-jumbotron {
    padding: 2rem 2rem 2rem calc(2rem + 88px - 66px);
    margin-left: 66px;
    position: relative;

    *:last-child {
        margin-bottom: 0;
    }

    .icon {
        width: 98px;
        height: 78px;
        position: absolute;
        left: -66px;
        top: calc(50% + 10px);
        transform: translateY(-50%);
    }

    .h3 {
        font-size: rem(24);
        font-weight: normal;
    }
}