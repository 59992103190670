.logo {
    text-decoration: none !important;
}

.navbar-brand.logo {
    @include media-breakpoint-down(md) {
        img {
            width: auto;
            height: 40px;
        }
    }
}