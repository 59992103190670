@import "swiper/swiper";
@import "swiper/modules/navigation/navigation";
@import "swiper/modules/pagination/pagination";

:root {
  --swiper-theme-color: #b60146;
  --swiper-navigation-size: 32px;
}

.swiper {
  margin-left: -15px;
  margin-right: -15px;
  .card-publication { margin: 15px; }
}

.swiper-button-prev,
.swiper-button-next {
  position: relative;
  display: inline-block;
  left: auto;
  right: auto;
  height: auto;
  width: auto;
  margin-top: 5px;
}

.swiper-button-prev:after {
  @include fonticon('\f137');
  margin-left: 15px;
  margin-right: 5px;
}

.swiper-button-next:after {
  @include fonticon('\f138');
}
