#navbarsHeader {
    ul {
        text-transform: uppercase;
    }
}

.navbar {
    position: static;

    .navbar-collapse {
        .dropdown-menu {
            border: 0;
            border-radius: 0;
    
            .dropdown-item a {
                color: $body-color;
                text-transform: none;
            }
    
            .dropdown-title a {
                color: $primary;
                text-transform: none;
                font-weight: 700;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        align-items: flex-end;

        .nav-link.active, .nav-item.active .nav-link {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                left: 1rem;
                bottom: -16px;
                right: 1rem;
                height: 4px;
                background-color: $primary;
            }
        }

        .navbar-collapse {
            .dropdown {
                position: static;
            }

            .dropdown-menu {
                background: $light;
                left: 0;
                right: 0;
                top: 98px;
                margin: 0;
                box-shadow: 0px 5px 15px 0px rgba(198, 195, 212, 0.7);

                .dropdown-title a {
                    font-size: 1.4rem;
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .navbar-brand {
        padding: 0;
        img {
            height: 45px;
        }
    }
}