#euCookie {
    z-index: 2147483651 !important; // higher than olark chat box
    // background: rgba($footer-bg, 0.95);
    background: white;
    // color: $white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;

    .btn {
        // background-color: $brand-coral !important;
        // color: $white !important;
    }

    ul {
        list-style: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
    }
}