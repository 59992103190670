.media {
    position: relative;

    .media-img {
        background-color: $secondary;
        color: white;
        text-align: center;
        height: 80px;
        width: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        i {
            font-size: rem(24);
        }

        .text {
            font-size: rem(10);
            margin-top: rem(8);
            text-transform: uppercase;

            &.text-lg {
                font-size: rem(12);
                margin-top: 0;
                line-height: 1.75;
            }
        }
    }

    .media-title {
        font-size: rem(20);

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                color: $purple;
            }
        }
    }

    .media-date,
    .media-categories {
        font-size: rem(14);
        color: $gray-500;
    }

    .media-date {
        position: absolute;
        top: 0;
        right: 0;
    }

    & + hr {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}