.wysiwyg-content {
    h1, .h1 {}

    h2, .h2 {
        font-weight: 600;
        margin-top: 2.5rem;
    }

    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6 {
        font-weight: 400;
    }

    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6 {
        margin-bottom: .75rem;
    }

    * + h3, * + .h3,
    * + h4, * + .h4,
    * + h5, * + .h5,
    * + h6, * + .h6 {
        margin-top: 1rem;
    }

    iframe {
        max-width: 100%;
    }

    ul, ol {
        overflow: auto;
        padding-left: 1.2rem;
    }
}
.page-body, .wysiwyg-content {
    img {
        padding: 0 1rem;

        &[style*="float:left"] {
            padding-left: 0;
        }

        &[style*="float:right"] {
            padding-right: 0;
        }
    }
}

$key-figure-size-lg: 840px;
$key-figure-size-md: 660px;
$key-figure-size-sm: 420px;

.key-figure-body {
    flex: 1;
}
.key-figure-sidebar {
    padding-left: 2rem;
}

@include media-breakpoint-up(md) {
    .key-figure-body {
        flex: 0 0 $key-figure-size-sm;
    }
    .key-figure-sidebar {
        flex: 0 0 calc(100% - #{$key-figure-size-sm});
    }
}

@include media-breakpoint-up(lg) {
    .key-figure-body {
        flex: 0 0 $key-figure-size-md;
    }
    .key-figure-sidebar {
        flex: 0 0 calc(100% - #{$key-figure-size-md});
    }
}

@include media-breakpoint-up(xl) {
    .key-figure-body {
        flex: 0 0 $key-figure-size-lg;
    }
    .key-figure-sidebar {
        flex: 0 0 calc(100% - #{$key-figure-size-lg});
    }
}