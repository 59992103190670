body.test {
    background: #fff url("../img/bg/test-content-bg.png") repeat 0 0;

    .navbar-light {
        background-color: transparent !important;
    }
}
  
body.accept {
    background: #fff url("../img/bg/accept-content-bg.png") repeat 0 0;

    .navbar-light {
        background-color: transparent !important;
    }
}

// #main-content {
//     @include media-breakpoint-down(md) {
//         padding-top: 79px;
//     }
// }

hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
}