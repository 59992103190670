@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    10% {
        transform: translateY(5px);
    }

    15% {
        transform: translateY(-4px);
    }

    30% {
        transform: translateY(2px);
    }

    45% {
        transform: translateY(-3px);
    }

    60% {
        transform: translateY(2px);
    }

    75% {
        transform: translateY(-3px);
    }

    90% {
        transform: translateY(1px);
    }

    100% {
        transform: translateY(0);
    }
}

.backtotop {
    background: $secondary;
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    position: fixed;
    bottom: 15px;
    right: 15px;
    border-radius: 24px;
    display: none;

    .backtotop-arrow {
        display: inline-block;
        color: white;
        transform: translateY(0);
        transition: 0.3s ease;
    }

    &:hover {
        .backtotop-arrow {
            animation: bounce 3s infinite;
        }
    }

    @include media-breakpoint-down(sm) {
        display: none;
    }
}