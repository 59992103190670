.dropdown-toggle::after {
    @include fonticon(\f078);
    border: 0;
    vertical-align: initial;
    font-size: rem(12);
    margin-left: 5px;
    color: $primary;
}

.dropdown-item.active, .dropdown-item:active {
    position: relative;
    background-color: transparent;
    color: $body-color;
    font-weight: bold;

    &:before {
        content: '';
        position: absolute;
        top: 0.4rem;
        left: 0.5rem;
        bottom: 0.4rem;
        width: 4px;
        background-color: $primary;
    }
}

.dropdown-title.active, .dropdown-title:active {
    &:before {
        left: 0;
    }
}

.dropdown-menu {
    overflow: hidden;
}