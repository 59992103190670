.chart {
    .bar {
        text {
            fill: $dark;
            transition: 0.2s ease;
        }

        rect {
            fill: $secondary;
            transition: 0.2s ease;
        }

        .chart-legend-value {
            fill: #fff !important;
        }
    }
}