.navbar-toggler {
    border:0;
    height:30px;
    padding:0;
    position:relative;
    transition:.25s ease-in-out;
    width:30px;
    outline: 0 !important;
    *{
        transition:.25s ease-in-out;  
    }
    > * > span {
        display:block;
        background: $dark;
        border-radius:2px;
    }
    #hamburger {
        padding-top:3px;
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        span {
            width:30px;
            height:3px;
            position:relative;
            margin:7px 0;
            &:nth-child(1){
                transition-delay:.5s;
                margin-top:0;
            }
            &:nth-child(2){
                transition-delay:.625s;
            }
            &:nth-child(3){
                transition-delay:.75s;
                margin-bottom:0;
            }
        }
    }
    #cross {
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        transform:rotate(45deg);
        span{
            background: $dark;
            &:nth-child(1){
                height:0%;
                width:3px;
                position:absolute;
                top:10%;
                left:13px;
                transition-delay:0s;
            }
            &:nth-child(2){
                width:0%;
                height:3px;
                position:absolute;
                left:10%;
                top:14px;
                transition-delay:.25s;
            }
        }
    }
    &[aria-expanded="true"] {
        #hamburger {
            span {
                width:0%;
                &:nth-child(1){
                    transition-delay:0s;
                }
                &:nth-child(2){
                    transition-delay:.125s;
                }
                &:nth-child(3){
                    transition-delay:.25s;
                }
            }
        }
        #cross {
            span {
                &:nth-child(1){
                    height:80%;
                    transition-delay:.625s;
                }
            }
            span {
                &:nth-child(2){
                    width:80%;
                    transition-delay:.375s;
                }
            }    
        }
    }
}