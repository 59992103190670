.pagination-sm {
    .page-link {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 15px !important;
        text-decoration: underline;
    }

    .page-item.active .page-link {
        text-decoration: none;
    }
}