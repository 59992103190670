.page-languages {
    [class*="col"]:first-child {
        .language-border {
            @include media-breakpoint-up(sm) {
                border-right: 1px solid $border-color;
                margin-right: -15px;
                padding-right: 15px;
            }
        }
    }
}