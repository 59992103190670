.card {
    color: $gray-500;

    &.card-publication {
        border: 0;

        .card-content {
            height: 100%;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                box-shadow: 0px 5px 15px 0px rgba(198, 195, 212, 0.7);
                background-color: white;
                z-index: 0;
                border-radius: $card-border-radius;
                transition: $transition-base;
            }

            > * {
                position: relative;
                z-index: 1;
            }
        }

        &:hover {
            h4 {
                color: $primary;
            }

            .card-content:before {
                top: -0.5rem;
                right: -0.5rem;
                bottom: -0.5rem;
                left: -0.5rem;
            }
        }
    }

    .card-header.bg-secondary {
        background-color: $green-accessible !important;
    }
}

.card-deck {
    .card-publication {
        padding: 25px;
        margin-left: 0;
        margin-right: 0;
        flex: 0 0 100%;

        @include media-breakpoint-up(md) {
            flex: 0 0 50%;
        }

        @include media-breakpoint-up(lg) {
            flex: 0 0 33.33333%;
        }

        &.card-publication-bigger {
          .card-content {
            &:before {
              background-color: $light;
            }
          }

          @include media-breakpoint-up(md) {
              flex: 0 0 100%;
          }

          @include media-breakpoint-up(lg) {
              flex: 0 0 66.667%;
          }

        }
    }
}

.card-deck.content-blocks .card {
    flex: 0 0 calc(33.3334% - 30px);
}

.card-hover {
    cursor: pointer;

    .card-title {
        font-size: 1.1rem;

        @include media-breakpoint-up(lg) {
            font-size: 1.4rem;
        }
    }

    &:hover {
        svg {
            .bar {
                text, rect {
                    fill: $primary;
                }
            }
        }
    }
}

.kerncijfer-number {
    font-size: 1.5rem;
}
