.leading {
    @include font-size(rem(18));
}

a {
	&[target="_blank"]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".docx"]):not([href$=".xsd"]):not([href$=".xml"]):not([href$=".zip"]):not([href$=".xls"]):not([href$=".xlsx"]):not(.btn):not(.no-icon) {
		&:after {
			margin-left: 5px;
            @include fonticon(\f35d);
            font-size: 0.7rem;
		}
	}

	&[href$=".pdf"]:not(.btn):not(.card) {
		&:after {
			margin-left: 5px;
            @include fonticon(\f1c1, 500);
            font-size: 0.7rem;
		}
	}

	&[href$=".zip"]:not(.btn):not(.card) {
		&:after {
			margin-left: 5px;
            @include fonticon(\f1c6, 500);
            font-size: 0.7rem;
		}
	}

	&[href$=".xsd"]:not(.btn):not(.card), &[href$=".xml"]:not(.btn):not(.card), &[href$=".xls"]:not(.btn):not(.card), &[href$=".xlsx"]:not(.btn):not(.card) {
		&:after {
			margin-left: 5px;
            @include fonticon(\f1c3, 500);
            font-size: 0.7rem;
		}
	}

	&[href$=".docx"]:not(.btn):not(.card), &[href$=".doc"]:not(.btn):not(.card) {
		&:after {
			margin-left: 5px;
            @include fonticon(\f1c2, 500);
            font-size: 0.7rem;
		}
	}
}

.display-5 {
	font-size: $display5-size;
	font-weight: $display5-weight;
	line-height: 1.2;
}

.list-fontawesome, .list-links {
	padding-left: 0;
	list-style: none;

	li {
		&:before {
			@include fonticon(\f054);
			color: $primary;
			margin-right: 0.5rem;
			font-size: 0.75rem;
			vertical-align: middle;
			display: inline-block;
		}

		& + li {
			margin-top: 0.5rem;
		}
	}
}

h1, .h1 {
	font-size: 2rem;
}

.wysiwyg-content {
	h1, .h1 {
		font-size: 2.25rem;
		@include media-breakpoint-up(sm) {
			font-size: 2.5rem;
		}
	}

  img[style] {
    @include img-fluid();
    margin: 0.5rem;
  }
}

a .fa, a .fas, a .fab {
	vertical-align: middle;
}
